import React, {Component} from "react";
import content from "./content"
import mapPic from "./assets/map.jpg";
import newYearGif from "./assets/new-year.gif"
import layout from "./assets/layout.png"
import zine1 from "./assets/zine1.png"
import zine2 from "./assets/zine2.png"
import zine3 from "./assets/zine3.png"
import zine4 from "./assets/zine4.png"
import journey1 from "./assets/journey1.png"


class Words extends Component {
	render(){
		let num = this.props.pos;
		if(num === 0){
			return null;
		} else if(num === 1){
			return(
				<div>
					<div className = "intro">{content.section1.intro}</div>
					<h2 className = "subtitles">{content.section1.subtitle1}</h2>
					<div className = "content">{content.section1.p1}</div>
					<div className = "linkOut">
						<a href = {content.section1.url1} className = "link">{content.section1.link1}</a>
					</div>					
					<h2 className = "subtitles">{content.section1.subtitle2}</h2>
					<img id = "map" src = {mapPic} alt = "oops"></img>
					<div className = "content">{content.section1.p2}</div>
					<div className = "linkOut">
						<a href = {content.section1.url2} className = "link">{content.section1.link2}</a>
						<a href = {content.section1.url3} className = "link">{content.section1.link3}</a>
					</div>
					<h2 className = "subtitles">{content.section1.subtitle3}</h2>
					<div className = "content">{content.section1.p3}</div>
					<div className = "linkOut">
						<a href = {content.section1.url4} className = "link">{content.section1.link4}</a>
					</div>
				</div>
			)

		} else if(num === 2){
			return(
				<div>
					<div className = "intro">{content.section2.intro}</div>
					<h2 className = "subtitles">{content.section2.subtitle1}</h2>
					<div className = "content">{content.section2.p1}</div>
					<div className = "disclaimer">{content.section2.disc}</div>
					<div className = "linkOut">
						<a href = {content.section2.url1} className = "link">{content.section2.link1}</a>
					</div>					
					<h2 className = "subtitles">{content.section2.subtitle2}</h2>
					<div className = "content">{content.section2.p2}</div>
				</div>
			)
		}else if(num === 3){
			return(
				<div>
					<div className = "intro">{content.section3.intro}</div>
					<div className = "altContent">{content.section3.p1}</div>				
					<div className = "altContent">{content.section3.p2}</div>
					<h4 className = "important">{content.section3.subtitle3}</h4>
					<div className = "altContent">{content.section3.p3}</div>
					<img id = "nyGif" src = {newYearGif} ></img>
					<div className = "linkOut">
						<ul className = "linkList">
							<a href = {content.section3.url1} className = "link">{content.section3.link1}</a>
							<a href = {content.section3.url2} className = "link">{content.section3.link2}</a>
							<a href = {content.section3.url3} className = "link">{content.section3.link3}</a>
							<a href = {content.section3.url4} className = "link">{content.section3.link4}</a>
						</ul>
						
					</div>
					<h4 className = "important">{content.section3.subtitle4}</h4>
					<div className = "altContent">{content.section3.p4}</div>
					<div className = "linkOut">
						<ul className = "linkList">
							<li><a href = {content.section3.url5} className = "link">{content.section3.link5}</a></li>
							<li><a href = {content.section3.url6} className = "link">{content.section3.link6}</a></li>
						</ul>
					</div>
					<div className = "altContent">{content.section3.p5}</div>
					<div className = "linkOut">
						<ul className = "linkList">
							<li><a href = {content.section3.url7} className = "link">{content.section3.link7}</a></li>
							<li><a href = {content.section3.url8} className = "link">{content.section3.link8}</a></li>
							<li><a href = {content.section3.url9} className = "link">{content.section3.link9}</a></li>
							<li><a href = {content.section3.url10} className = "link">{content.section3.link10}</a></li>
						</ul>
					</div>
				</div>
			)

		} else if (num === 4){ //ZINES
			return(
				<div>
					<div className = "intro">{content.section4.intro}</div>
					<div className = "content">{content.section4.p1}</div>
					<h2 className = "subtitles">{content.section4.subtitle1}</h2>
					<img class = "zines" id = "lay" src = {layout} alt = "oops"></img>
					<h2 className = "subtitles">{content.section4.subtitle2}</h2>
					<img class = "zines" id = "zineImg1" src = {zine1} alt = "oops"></img>
					<h2 className = "subtitles">{content.section4.subtitle3}</h2>
					<img class = "zines" id = "zineImg2" src = {zine2} alt = "oops"></img>
					<h2 className = "subtitles">{content.section4.subtitle4}</h2>
					<img class = "zines" id = "zineImg3" src = {zine3} alt = "oops"></img>
					<h2 className = "subtitles">{content.section4.subtitle5}</h2>
					<img class = "zines" id = "zineImg4" src = {zine4} alt = "oops"></img>
				</div>
			)

		} else if (num === 5){ //PHOTOS
			return(
				<div>
					<div className = "intro">{content.section5.intro}</div>
					<div className = "content">{content.section5.p1}</div>
					<div className = "content">{content.section5.p2}</div>
					<div className = "linkOut">
						<a href = {content.section5.url1} className = "link">{content.section5.link1}</a>
					</div>
					<h2 className = "subtitles">{content.section5.subtitle1}</h2>
					<img class = "photos" id = "j1" src = {journey1} alt = "oops"></img>
				</div>
			)

		} else if (num === 6){ //CONTACT
			return(
				<div>
					<div className = "linkOut">
						<ul className = "linkList">
							<li><a href = {content.section6.url1} className = "link">{content.section6.link1}</a></li>
							<li><a href = {content.section6.url2} className = "link">{content.section6.link2}</a></li>
							<li><a href = {content.section6.url3} className = "link">{content.section6.link3}</a></li>
							<li><a href = {content.section6.url4} className = "link">{content.section6.link4}</a></li>
						</ul>
					</div>


				</div>
			)

		}
	}
}

export default Words;