//sort out season length extremities - no episode 0 and no season length + 1 !!

import React, {Component} from "react";
import Words from "../words"
import p5 from "p5"

class Counter extends Component {
	constructor(props){
		super(props)
		this.state = {
			width: 0,
			height: 0,
			title: "Joseph Brown",
			list: 0,
			position: 0
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
		this.myRef = React.createRef()
		this.contentRef = React.createRef();
	}

	componentDidMount() {
		this.myP5 = new p5(this.Sketch, this.myRef.current)
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions(){
		this.setState({width:window.innerWidth,height:window.innerHeight})
	}

	linker = (num) =>{

		const cont = this.contentRef.current;
		cont.classList.remove("visible")
		let pagePos = num.id;
		if(pagePos === 0){
			this.setState({title: "Computers With Music"})
			this.setState({list: 1})
			this.setState({position:1})
		} else if(pagePos === 1) {
			this.setState({title: "Computers No Music"})
			this.setState({list: 1})
			this.setState({position:2})
		} else if(pagePos === 2) {
			this.setState({title: "Music All By Itself"})
			this.setState({list: 1})
			this.setState({position:3})
		} else if(pagePos === 3) {
			this.setState({title: "Zines"})
			this.setState({list: 1})
			this.setState({position:4})
		} else if(pagePos === 4) {
			this.setState({title: "Photography"})
			this.setState({list: 1})
			this.setState({position:5})
		} else if(pagePos === 5) {
			this.setState({title: "Contact"})
			this.setState({list: 1})
			this.setState({position:6})
		}

		setTimeout(function(){
			cont.classList.add("visible")
		},50)
		
	}

	Sketch = (p) => {
		//adapted from the p5 guide on L-systems - but made BETTER
		let x, y; 
		let currentangle = 0; 
		let step = 20; 
		let angle = 60; 
		let inc = 0
		let radius = 15;

		let opacity = 100;

		let userX
		let userY
		
		let thestring = 'XF'; 
		let numloops = 8; 
		let therules = []; 
		let looper = 50;
		therules[0] = ['X', 'X+YF++YF-FX--FXFX-YF+'];
		therules[1] = ['Y', '-FX+YFYF++YF+FX--FX-Y'];

		let whereinstring = 0;

		p.setup = () => {
			p.createCanvas(this.state.width, this.state.height*4);
			p.background(255,255,255,0);
			x = p.width - p.width/5;
			y = p.height/8;

			for (let i = 0; i < numloops; i++) {
			 	thestring = p.lindenmayer(thestring);
			}

			setTimeout(function(){
				setInterval(p.drawer,100)
			},2000)
			
		}

		p.drawer = () => {
			drawIt(thestring[whereinstring]);

			whereinstring++;
			if (whereinstring > looper) {
				whereinstring = 0;
				x = p.mouseX;
				y = p.mouseY;
				// x = p.random(100, p.width-100);
				// y = p.random(50,p.height)
				looper = p.round(p.random(50,200))
				opacity= p.random(50,150)
			}
		}

		p.lindenmayer = (s) => {
			let outputstring = '';

			for (let i = 0; i < s.length; i++) {
				let ismatch = 0; 
				for (let j = 0; j < therules.length; j++) {
					if (s[i] === therules[j][0])  {
						outputstring += therules[j][1]; 
						ismatch = 1;
						break;
					}
				}
				
				if (ismatch === 0) outputstring+= s[i];
			}
			return outputstring;
		}

	    function drawIt(k) {

			if(inc === 0 || inc === 1){
				if (k==='F') { 
					p.stroke(255, 163, 26,opacity)
					p.strokeWeight(4)
					p.fill(255)
					let x1 = x + step*p.cos(p.radians(currentangle));
					let y1 = y + step*p.sin(p.radians(currentangle));
					p.ellipse(x, y, radius,radius); 

					// update the turtle's position:
					x = x1;
					y = y1;
				} else if (k === '+') {
					currentangle += angle; // turn left
				} else if (k === '-') {
					currentangle -= angle; // turn right
				}

			} else {
				if (k==='F') { // draw forward
					p.stroke(255, 163, 26,opacity)
					p.strokeWeight(4)
					p.fill(255, 163, 26)
					// polar to cartesian based on step and currentangle:
					let x1 = x + step*p.cos(p.radians(currentangle));
					let y1 = y + step*p.sin(p.radians(currentangle));
					p.line(x, y, x1, y1); // connect the old and the new
					// update the turtle's position:
					x = x1;
					y = y1;
				} else if (k === '+') {
					currentangle += angle; // turn left
				} else if (k === '-') {
					currentangle -= angle; // turn right
				}
			}
			
			inc++
			if(inc === 13){inc = 0}
		}

		p.mousePressed = () =>{
			if(p.mouseButton === p.LEFT){
				p.stroke(255, 163, 26,opacity)
				p.strokeWeight(4)
				p.fill(255)
				let newAngle = 60;
				let newX1 = p.mouseX;
				let newY1 = p.mouseY;	
				for(let j = 0; j < 4; j++) {
					p.ellipse(newX1,newY1,radius,radius)
					for(let i=0;i<4;i++){
						let newX2 = newX1 + step*p.cos(p.radians(newAngle))
						let newY2 = newY1 + step*p.sin(p.radians(newAngle))
						p.line(newX1,newY1,newX2,newY2)
						newX1 = newX2;
						newY1 = newY2;
						newAngle += p.random() < 0.5 ? -60 : 60;
					}
					newX1 += 5;
					newY1 += 5;
				}
				
			}
		}

	}

	render() {
		let listClass = "listHome";
		let titleId = "titleHome";
		let linkClass = "linkHome";
		let pos = this.state.position;

		listClass = this.state.list === 0 ? "listHome" : "listOther";
		titleId = this.state.list === 0 ? "titleHome" : "titleOther";
		linkClass = this.state.list === 0 ? "linkHome" : "linkOther";

		return (
			<div className = "pageContents">
				<div className = "canvas" ref = {this.myRef}></div>
				<div ref = {this.contentRef} className = "contents visible">
					<h1 className = "title" id={titleId}>{this.state.title}</h1>
					<div className = {listClass}>
					<ul className = "nav">
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:0})}}>Code I</a></li>
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:1})}}>Code II</a></li>
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:2})}}>Music</a></li>
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:3})}}>Zines</a></li>
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:4})}}>Photography</a></li>	
						<li className = {linkClass}><a href = "#" onClick = {() => {this.linker({id:5})}}>Contact</a></li>		
					</ul>
				</div>

				<Words pos = {pos} />
				</div>
			</div>
		);	
	}
}

export default Counter;